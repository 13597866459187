.footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #e0e0e0;
    background-color: #171717;
    margin-top: 10px;
}


.contentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    margin-left: 385px;
    margin-bottom: 50px;

}

.textBelowImage {
    padding-top: 0px;
    margin-top: 0px;
    margin-left: 20px;
    color: goldenrod;
}

.iconContainer {
    margin-top: 10px;
    margin-left: 20px;
}

.iconItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
   

   
}

.iconItem span {
    margin-left: 10px;
    color: white;

}

.iconClass {
    margin-right: 10px;
}

.learnMoreButton {
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 20px;
    padding: 15px 30px;
    height: 60px;
    width: 450px;
    font-weight: 600;
    background-color: #32CE48;
    border-radius: 100px 100px 100px 100px;
    
}

.learnMoreButton:hover {
    background-color: rgba(50, 206, 72, 0.9); /* #32CE48 with 90% opacity */
}



.mapContainer {
    
    width: 600px;
    height: 450px;
    overflow: hidden;
    border-radius: 10px;
    margin-right: 235px;
   
}

.map {
    margin-top: 20px;
    border-radius: 10px;

}


@media (max-width: 576px) {
    .footerContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;        /* Centering text for direct child elements */
    }

    .contentContainer {
        margin: 0;                 /* Resetting margin */
        width: 100%;               /* Taking full width of the container */
        align-items: center;
    }

    .textBelowImage {
        margin-left: 0;            /* Resetting the left margin to align in center */
        text-align: center;
    }

    .iconContainer {
        width: 100%;               /* Taking full width of the container */
    }

    .iconItem {
        justify-content: center;  /* Centering icons with the text */
        width: 100%;               /* Full width for center alignment */
    }

    .iconItem a {
        display: flex;
        justify-content: center;  /* Centering content of anchor links */
        width: 100%;
    }

    .iconItem span {
        margin-left: 10px;        /* Space between icon and text */
    }

    .learnMoreButton {
        width: 80%;
        font-size: 16px;
        padding: 12px 25px;
        height: 50px;
        margin: 10px auto;
        padding-bottom: 50px;
    }

    .mapContainer {
        width: 90%;
        height: 300px;
        margin: 15px auto;        /* Centering map with auto side margins */
    }

    

}
