.mySwiper {
    margin-top: 100px;
    width: 63%;
    height: 570px;
    margin-bottom: 140px;

}



.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.cardImage {
    width: 100%;
    height: 500px;
    /* you can adjust this based on your preference */
    object-fit: cover;
    padding-bottom: 0px;
    
}

.cardText {
    padding: 10px;
    background-color: #f7f7f7;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

@media (max-width: 576px) {

    .mySwiper {
        width: 352px;
        
        margin-top: 50px;
        margin-bottom: 180px;


    }

    .card {
        height: auto;
        /* Let height be determined by the content */
    }

    .cardImage {
        height: 480px;
        /* Reduce image height for mobile */
    }

    .cardText {
        font-size: 16px;
    }
}