.heroContainer {
    background-image: url('../../assets/backgroundHero.png');
    background-size: cover;
    background-position: center;
    height: 80vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 20px;
}

h1,
p {
    max-width: 800px;
    width: 100%;
}


h1 {
    font-size: 35px;

}

p {
    font-size: 18px;
}

.heroButton {
    margin-top: 20px;
    padding: 15px 30px;
    font-size: 18px;
    border: none;
    background-color: #a08c7f;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.heroButton:hover {
    background-color: #937d6e;
}

@media (max-width: 576px) {

    .heroContainer {
        background-position:  right -160px center; /* or any other value you prefer */
        background-image: linear-gradient(rgba(0, 0, 0, 0.459), rgba(0, 0, 0, 0.473)), url('../../assets/backgroundHeroMobile.webp');
        height: 100vh;


    }

    h1 {
        font-size: 26px; /* Adjust based on preference */
    }

    p {
        font-size: 17px; /* Adjust based on preference */
    }

    .heroButton {
        
        padding: 20px 40px;
        font-size: 25px;
    }

    .logo {
        position: relative;
        height: 130px;
        bottom: 120px;
    }

    .itensDiv {
        margin-top: 70px;
    }
}