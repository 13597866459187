.container {
    background-color: #A5CDD9;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #FAFAFA;
    border-bottom: 4px solid #E9ECED; /* Added border-bottom */
    height: 75px;
}


.logo {
    height: 30px;
}

.flexicons {
    display: flex;
    gap: 30px;
}

.iconandtext {
    color: black;
    
}

.pcolor {
    text-align: center;
}

.number {
    position: relative;
    bottom: 5px;
}

.card {
    transition: all 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
  }



