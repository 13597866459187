.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;




}

.title {
  font-size: 48px;
  font-family: 'Roboto Slab', sans-serif;
  text-align: center;
  color: #474747;
  position: relative;
  display: inline-block;
}

.title::before,
.title::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 1px;
  background-color: #474747;
  margin: 0 10px;
}

.cardsContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;

}

.cardsContainer2 {
  display: flex;
  justify-content: center;
  gap: 20px;
  /* Spacing between the cards */
  margin-top: 30px;
}

.card {
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  border-radius: 8px;
  width: 200px;
  transition: box-shadow 0.3s;
  margin-bottom: 0;
  /* Ensure there's no margin at the bottom */

}

.card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.iconContainer {
  font-size: 40px;
  /* You might not need this anymore if you're not using font icons */
  margin-bottom: 20px;
  display: flex;
  /* Use flexbox */
  justify-content: center;
  /* Center the child horizontally */
  align-items: center;
  /* Center the child vertically */

}

.iconImage {
  width: 100%;
  /* Adjust as needed */
  height: auto;
  max-width: 80px;
  /* Adjust as needed */
  max-height: 80px;
  /* Adjust as needed */
  display: block;
  /* This will remove any bottom space caused by inline behavior */
  margin: 0;
  /* Ensure there's no margin */
  padding: 0;
  /* Ensure there's no padding */
}

.cardText {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 0;
  /* Ensure there's no margin at the bottom */

}


.iconClass {}

.learnMoreButton {
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 20px;
  height: 60px;
  width: 450px;
  padding-left: 71px;

  font-weight: 600;
  background-color: #32CE48;
  border-radius: 100px 100px 100px 100px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 80px;

}

.learnMoreButton:hover {
  background-color: rgba(50, 206, 72, 0.9);
  /* #32CE48 with 90% opacity */
}

@media (max-width: 576px) {

  .cardsContainer,
  .cardsContainer2 {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%;
    /* takes up 90% of the viewport width, but you can adjust this as needed */
    margin-bottom: 20px;
    /* spacing between stacked cards */
  }

  .title {
    font-size: 32px;
    /* Slightly smaller font size for mobile */
  }

  .cardText {
    font-size: 18px;
    /* Slightly smaller font size for mobile */
  }

  .learnMoreButton {
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 20px;
    height: 70px;
    width: 330px;
    padding-left: 51px;
    padding-right: 20px;
    font-weight: 600;
    background-color: #32CE48;
    border-radius: 100px 100px 100px 100px;
    display: flex;
    align-items: center;
    gap: 0px;
    margin-top: 15px;
   
  }


}