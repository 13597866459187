.border {
    border-top: 1px solid #474747;
    margin-top: 30px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    max-width: 1750px;
    justify-content: space-evenly;
    


}

.contentGrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.title {
    font-size: 48px;
    font-family: 'Roboto Slab', sans-serif;
    color: #474747;
    width: 400px;
    margin-bottom: 0px;

}

.ptext {
    text-align: justify;
    color: #2F2F2F;
    font-size: 20px;
    font-weight: 500;
    width: 600px;
    padding-left: 200px;
    /* Optional: Add some padding if needed */

}

.imageGrid {
    display: flex;
    justify-content: center;
    align-items: center;


}

.aboutImage {
    width: 550px;
    height: auto;
    border-radius: 10px;
}

.iconClass {
}

.learnMoreButton {
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 20px;
    height: 60px;
    width: 450px;
    padding-left: 71px;
    font-weight: 600;
    background-color: #32CE48;
    border-radius: 100px 100px 100px 100px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.learnMoreButton:hover {
    background-color: rgba(50, 206, 72, 0.9);
    /* #32CE48 with 90% opacity */
}


@media (max-width: 576px) {
    .container {
        flex-direction: column;
        /* Stack elements vertically */
        margin-top: 30px;
        /* Decrease the top margin */
    }

    .contentGrid {
        order: 2;
        /* Ensure the text/content appears below the image on mobile */
        padding: 0px 0;
        /* Give it some padding top and bottom */
    }

    .title {
        text-align: center;
        font-size: 32px;
        /* Slightly smaller font size for mobile */
        width: 90%;
        /* Adjust width for mobile screens */
        margin-bottom: 10px;
        /* Reduce the space below the title */
    }

    .ptext {
        font-size: 18px;
        /* Slightly smaller font size for mobile */
        width: 80%;
        /* Make the text width 100% */
        padding-left: 0;
        /* Remove the padding on the left for mobile */
    }

    .imageGrid {
        order: 1;
        /* Ensure the image appears above the text on mobile */
        margin-bottom: -10px;
        /* Space between the image and the content */
    }

    .aboutImage {
        width: 90%;
        /* Adjust the width for mobile screens */
        max-width: 400px;
        /* Set a maximum width */
    }

    .learnMoreButton {
        border: none;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        font-size: 20px;
        height: 70px;
        width: 330px;
        padding-left: 51px;
        padding-right: 20px;
        font-weight: 600;
        background-color: #32CE48;
        border-radius: 100px 100px 100px 100px;
        display: flex;
        align-items: center;
        gap: 0px;
    }
}